import { GlobalPositionStrategy, OverlayPositionBuilder } from '@angular/cdk/overlay';

import {
  OFFSET_MODIFIER,
  ToastAnimationState,
  ToastOptions,
  ToastPosition,
  ToastRef,
  ToastVerticalPosition,
} from '../../models/services/toast.model';

export function getAnimationDirection(position: ToastPosition): ToastAnimationState {
  if (position.horizontalPosition === 'left') return 'fromLeft';
  else if (position.horizontalPosition === 'right') return 'fromRight';
  else if (position.verticalPosition === 'top') return 'fromTop';

  return 'fromBottom';
}

export function getPositionOffset(toasts: ToastRef[], verticalPosition: ToastVerticalPosition): number {
  if (verticalPosition === 'top') calculateTopOffset(toasts[toasts.length - 1]);

  return calculateBottomOffset(toasts[toasts.length - 1], toasts.length);
}

export function getToastPosition<T>(options?: ToastOptions<T>): ToastPosition {
  const horizontalPosition = options?.horizontalPosition ?? 'center';
  const verticalPosition = options?.verticalPosition ?? 'bottom';

  return { horizontalPosition, verticalPosition };
}

export function getPositionStrategy(
  positions: ToastPosition,
  positionOffset: number,
  overlayPositionBuilder: OverlayPositionBuilder,
): GlobalPositionStrategy {
  const [verticalOffset, horizontalOffset] = [`${positionOffset}px`, `${OFFSET_MODIFIER}px`];
  const { horizontalPosition, verticalPosition } = positions;
  const strategy = overlayPositionBuilder.global();

  switch (horizontalPosition) {
    case 'left':
      strategy.left(horizontalOffset);
      break;
    case 'right':
      strategy.right(horizontalOffset);
      break;

    default:
      strategy.centerHorizontally();
      break;
  }

  verticalPosition === 'top' ? strategy.top(verticalOffset) : strategy.bottom(verticalOffset);

  return strategy;
}

function calculateTopOffset(lastToast?: ToastRef): number {
  const position = lastToast?.getPosition()?.bottom ?? OFFSET_MODIFIER;

  return position + OFFSET_MODIFIER;
}

function calculateBottomOffset(lastToast?: ToastRef, toastCount = 0): number {
  if (toastCount === 0) return OFFSET_MODIFIER;

  return (lastToast?.getPosition()?.height ?? OFFSET_MODIFIER) + (lastToast?.getOffset() ?? 0) + OFFSET_MODIFIER;
}
