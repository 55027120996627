import { Injectable, Injector, Type } from '@angular/core';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { ToastComponent } from './toast.component';

import { OFFSET_MODIFIER, ToastData, ToastOptions, ToastRef } from '../../models/services/toast.model';

import {
  getAnimationDirection,
  getPositionOffset,
  getPositionStrategy,
  getToastPosition,
} from '../../utils/services/toast.util';
import { TOAST_DATA, TOAST_REF } from '../../utils/tokens.util';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastRefs: ToastRef[] = [];

  public constructor(
    private readonly overlay: Overlay,
    private readonly injector: Injector,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
  ) {}

  public show<T>(content: Type<unknown> | string, options?: ToastOptions<T>): ToastRef {
    const position = getToastPosition<T>(options);
    const positionGroup = `${position.horizontalPosition}-${position.verticalPosition}`;

    const relevantToasts = this.toastRefs.filter((toastRef) => toastRef.positionGroup === positionGroup);
    const positionOffset = getPositionOffset(relevantToasts, position.verticalPosition);

    const positionStrategy = getPositionStrategy(position, positionOffset, this.overlayPositionBuilder);
    const overlayRef = this.overlay.create({
      positionStrategy,
      panelClass: options?.hasCloseButton ? 'has-close-button' : undefined,
    });

    const toastRef = new ToastRef(overlayRef, positionOffset, positionGroup);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: TOAST_DATA, useValue: { content, options } as ToastData<T> },
        { provide: TOAST_REF, useValue: toastRef },
      ],
    });

    const tooltipPortal = new ComponentPortal(ToastComponent, undefined, injector);
    const componentRef = overlayRef.attach(tooltipPortal);
    componentRef.instance.animationState = getAnimationDirection(position);

    toastRef.setComponentRef(componentRef);

    componentRef.onDestroy(() => {
      const toastIndex = this.toastRefs.findIndex((toast) => toast === toastRef);
      const removedToast = this.toastRefs.splice(toastIndex, 1)[0];

      const offset = removedToast.getOffset() === 0 ? 0 : OFFSET_MODIFIER;

      this.toastRefs
        .filter(
          (toastRef) =>
            toastRef.positionGroup === removedToast.positionGroup && toastRef.getOffset() > removedToast.getOffset(),
        )
        .forEach((toastRef) => {
          const newOffset = toastRef.getOffset() - toastRef.getPosition()!.height - offset;
          toastRef.setOffset(newOffset);

          const positionStrategy = getPositionStrategy(position, newOffset, this.overlayPositionBuilder);
          toastRef.updatePosition(positionStrategy);
        });
    });

    this.toastRefs.push(toastRef);

    return toastRef;
  }

  public closeAll(): void {
    this.toastRefs.forEach((toastRef) => (toastRef.getComponentRef()!.instance.animationState = 'hidden'));
  }
}
